export const modalKeys = {
  IMPORTANT_NOTICE: 'IMPORTANT_NOTICE',
  PAIR_SELECTION: 'PAIR_SELECTION',
  RECEIVED_USER_DATA: 'RECEIVED_USER_DATA',
  ENTITY_TYPE_DECLARATION: 'ENTITY_TYPE_DECLARATION',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  WITHDRAWAL_TIER_UPGRADE: 'WITHDRAWAL_TIER_UPGRADE',
  COMPLIANCE_APPROVE: 'COMPLIANCE_APPROVE',
  COMPLIANCE_REJECT: 'COMPLIANCE_REJECT',
  COMPLIANCE_DOWNGRADE_USER_TIER: 'COMPLIANCE_DOWNGRADE_USER_TIER',
  SIGN_FORM_A: 'SIGN_FORM_A',
  FORM_B: 'FORM_B',
  UPGRADE_TIER_3_WARNING: 'UPGRADE_TIER_3_WARNING',
  PENDING_FOR_REVIEW: 'PENDING_FOR_REVIEW',
  CLAIM_REWARDS: 'CLAIM_REWARDS',
  TRANSFER_ON_PROCESSING: 'TRANSFER_ON_PROCESSING',
  TRANSACTION_DETAILS: 'TRANSACTION_DETAILS',
  PROVIDE_REQUESTED_DOCUMENTS: 'PROVIDE_REQUESTED_DOCUMENTS',
  CHANGE_TIER_WARNING: 'CHANGE_TIER_WARNING',
  MAINTENANCE_BANNER: 'MAINTENANCE_BANNER',
  MARKET_ORDER_WARNING: 'MARKET_ORDER_WARNING',
  USER_DETAILS: 'USER_DETAILS',
  FIAT_PAYMENT_DETAILS: 'FIAT_PAYMENT_DETAILS',
  API_KEYS: 'API_KEYS',
  API_KEYS_DETAILS: 'API_KEYS_DETAILS',
  ADDRESS_VERIFICATION_FAILED: 'ADDRESS_VERIFICATION_FAILED',
  ADDRESS_VERIFICATION_SUCCESSFUL: 'ADDRESS_VERIFICATION_SUCCESSFUL',
  API_KEYS_DELETE: 'API_KEYS_DELETE',
} as const;

export type IModalKey = keyof typeof modalKeys;
